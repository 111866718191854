import React, { useState, useEffect } from 'react';
import { emptyKeyResults, emptyPerformanceForm } from '../../../../../../helpers/constant';
import { getCompetencyOption, getObjectiveOption } from '../../../../../../helpers/functions';
import { API } from '../../../../../../utils/apis';
import BehaviouralCompetency from '../../competency/behavioural';
import TechnicalCompetency from '../../competency/technical';
import GoalsLayout from '../../goals-layout';
import AddCompetencyModal from '../../goals-layout/add-competency-modal';
import PerformanceOnly from '../performance-only';
import { useNavigate } from 'react-router';

const PerformanceCompetencyGoals = ({ employees }) => {
  const emptyForm = {
    performanceId: 0,
    employeeId: 0,
    departmentId: 0,
    employeeGroupId: 0,
    performanceTemplate: 0,
    approverId: 0,
    isDraft: true,
    goal: [
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 1,
      },
    ],
    behavioural: [
      {
        competencyId: 0,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 1
          }
        ],
        competencyType: 1,
        competencyCategory: 1,
        currentProficiencyLevel: 0,
        targetProficiencyLevel: 0
      }
    ],
    technical: [
      {
        competencyId: 0,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 1
          }
        ],
        competencyType: 1,
        competencyCategory: 1,
        currentProficiencyLevel: 0,
        targetProficiencyLevel: 0
      }
    ],
    groupType: 1,
  };
  const [formData, setFormData] = useState(emptyForm);
  const [employeeId, setEmployeeId] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [objectiveOption, setObjectiveOption] = useState([]);
  const [competencyOption, setCompetencyOption] = useState([]);
  const navigate = useNavigate();

  const loadObjectiveOptions = async () => {
    const res = await API.getObjective();
    setObjectiveOption(getObjectiveOption(res));
  };

  useEffect(() => {
    loadObjectiveOptions();
  }, []);

  const loadCompetencyOptions = async () => {
    const res = await API.getTechnicalCompetency();
    setCompetencyOption(getCompetencyOption(res));
  };

  useEffect(() => {
    loadCompetencyOptions();
  }, []);

  const submitData = async (e) => {
    setLoading(true);
    console.log(formData);
    await API.submitData(formData, navigate);
    setLoading(false);
    e.preventDefault();
  };

  return (
    <GoalsLayout
      employees={employees}
      title='Performance Goals + Competencies'
      setShowModal={setShowModal}
      setEmployeeId={setEmployeeId}
      submitData={submitData}>
      <PerformanceOnly 
       formData={formData} 
       setFormData={setFormData}
       objectiveOption={objectiveOption}
      />
      
      <BehaviouralCompetency
        formData={formData}
        setFormData={setFormData}
        competencyOption={competencyOption}
      />
      
      <TechnicalCompetency
        formData={formData}
        setFormData={setFormData}
        competencyOption={competencyOption}
      />
      
      <AddCompetencyModal
        show={showModal}
        onClose={() => setShowModal(false)}
        setOptions={setCompetencyOption}
      />
    </GoalsLayout>
  );
};

export default PerformanceCompetencyGoals;
