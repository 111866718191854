import { JOB_STATUS, JOB_TYPE, LEAVE_STATUS, LEAVE_TYPE, MEETING_TYPE } from './constant';

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getLeaveType = (leaveRequestType) => {
  if (leaveRequestType === 1) {
    return LEAVE_TYPE[1];
  } else if (leaveRequestType === 2) {
    return LEAVE_TYPE[2];
  } else if (leaveRequestType === 3) {
    return LEAVE_TYPE[3];
  } else if (leaveRequestType === 4) {
    return LEAVE_TYPE[4];
  } else if (leaveRequestType === 5) {
    return LEAVE_TYPE[5];
  } else if (leaveRequestType === 6) {
    return LEAVE_TYPE[6];
  } else if (leaveRequestType === 7) {
    return LEAVE_TYPE[7];
  } else if (leaveRequestType === 8) {
    return LEAVE_TYPE[8];
  }
};

export const getApprovalStatus = (approvalStatus) => {
  if (approvalStatus === 0) {
    return LEAVE_STATUS.PENDING;
  } else if (approvalStatus === 1) {
    return LEAVE_STATUS.APPROVED;
  } else if (approvalStatus === 2) {
    return LEAVE_STATUS.REJECTED;
  }
};

export const getEmployeeType = (employeeType) => {
  if (employeeType == 1) {
    return JOB_TYPE[0]?.label;
  } else if (employeeType == 2) {
    return JOB_TYPE[1]?.label;
  } else if (employeeType == 3) {
    return JOB_TYPE[2]?.label;
  } else if (employeeType == 4) {
    return JOB_TYPE[3]?.label;
  } else if (employeeType == 5) {
    return JOB_TYPE[4]?.label;
  }
};

export const getMeetingType = (meetingType) => {
  if (meetingType == 1) {
    return MEETING_TYPE[0]?.label;
  } else if (meetingType == 2) {
    return MEETING_TYPE[1]?.label;
  } else if (meetingType == 3) {
    return MEETING_TYPE[2]?.label;
  }
};

export const getJobStatus = (jobStatus) => {
  if (jobStatus == 1) {
    return JOB_STATUS[0]?.label;
  } else if (jobStatus == 2) {
    return JOB_STATUS[1]?.label;
  } else if (jobStatus == 3) {
    return JOB_STATUS[2]?.label;
  }
};

export const getJobType = (jobType) => {
  return (
    jobType === '1' || jobType === '2' || jobType === '3' || jobType === '4' || jobType === '5'
  );
};

export const getEmployeesOption = (employees) => {
  let EMPLOYEES_ARRAY = [];
  for (let i = 0; i < employees.length; i++) {
    EMPLOYEES_ARRAY.push({ label: employees[i]?.fullName, value: employees[i]?.id });
  }
  return EMPLOYEES_ARRAY;
};

export const getCompetencyOption = (list) => {
  let ARRAY = [];
  for (let i = 0; i < list.length; i++) {
    ARRAY.push({ label: list[i]?.name, value: list[i]?.value });
  }
  return ARRAY;
};

export const getObjectiveOption = (list) => {
  return list.map(item => ({
    label: item.objective,
    value: item.keyResults.map(kr => ({
      label: kr.keyResult,
      value: kr.keyResult,
    })),
  }));
};

export const getOvertimeOption = (list) => {
  let ARRAY = [];
  for (let i = 0; i < list.length; i++) {
    ARRAY.push({ label: list[i]?.name, value: list[i]?.value });
  }
  return ARRAY;
};
