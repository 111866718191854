import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import Select from '../../../../components/input/Select';
import { emptyObjForm, emptyObjResultForm, CYCLE, METRIC_TYPE } from '../../../../helpers/constant';
import { API } from '../../../../utils/apis';
import { useNavigate } from 'react-router-dom';

const SetObjectives = () => {
  const [formData, setFormData] = useState([emptyObjForm]);
  const [departments, setDepartments] = useState([]);
  const [departmentsOption, setDepartmentOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const addKeyResults = (index) => {
    formData[index].keyResults = [...formData[index].keyResults, emptyObjResultForm];
    setFormData([...formData]);
  };

  const deleteKeyResults = (index, i) => {
    formData[index].keyResults = formData[index].keyResults.filter((item, ind) => ind !== i);
    setFormData([...formData]);
  };

  const addObj = () => {
    setFormData([...formData, {...emptyObjForm}]);
  };

  const deleteObj = (ind) => {
    setFormData((formData) => formData.filter((item, i) => i !== ind));
  };
  const handleChangeFormData = (i, e) => {
    const { name, value } = e.target;
    formData[i] = { ...formData[i], [name]: value };
    setFormData([...formData]);
  };

  const handleChangeKeyResults = (i, ind, e, name, isObj) => {
    if (isObj) {
      formData[i].keyResults[ind] = { ...formData[i].keyResults[ind], [name]: e };
    } else {
      const { name, value } = e.target;
      formData[i].keyResults[ind] = { ...formData[i].keyResults[ind], [name]: value };
    }
    setFormData([...formData]);
  };

  const handleChangeMetrics = (ind, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    
    const updatedFormData = formData.map((item, index) => {
      if (index === ind) {
        return { ...item, metrics: value };
      }
      return item;
    });
    setFormData(updatedFormData);
  }; 
  
  const handleChangeCycle = (ind, selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';

    const updatedFormData = formData.map((item, index) => {
      if (index === ind) {
        return { ...item, cycle: value };
      }
      return item;
    });
    setFormData(updatedFormData);
  };

  const saveObjective = async (e) => {
    setLoading(true);
    console.log(formData);
    await API.saveObjective(formData, navigate);
    setLoading(false);
    e.preventDefault();
    window.location.reload();
  };

  const getDepartments = (department) => {
    let DEPARTMENT_ARRAY = [];
    for (let i = 0; i < department.length; i++) {
      DEPARTMENT_ARRAY.push({ label: department[i]?.name, value: department[i]?.id });
    }
    setDepartmentOption(DEPARTMENT_ARRAY);
  };

  const loadData = async () => {
    setLoading(true);
    const departmentRes = await API.fetchAllDepartment();
    if (departmentRes) setDepartments(departmentRes);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    getDepartments(departments);
  }, [departments]);

  

  return (
    // <BaseModal
    //   title={'set coperative'}
    //   coloredText='objectives '
    //   show={show}
    //   onClose={toggleModal}
    //   wide>
    <div className='space-y-6'>
      <form onSubmit={saveObjective} className='space-y-10 mt-4'>
        {formData.map((item, i) => (
          <div key={i} className='space-y-8  bg-white-30 shadow-xl rounded-3xl p-4 md:p-10'>
            <div className=' flex flex-wrap justify-between gap-5'>
              <div className='flex gap-5 items-start w-full mdLg:w-[45%] '>
                {formData.length > 1 && <h6>{i + 1}.</h6>}
                <Input
                  withLabel
                  label={'INPUT OBJECTIVE'}
                  containerClass='w-full'
                  value={item?.objective}
                  name='objective'
                  onChange={(e) => handleChangeFormData(i, e)}
                />
              </div>

              <div className='w-[50%]'>
              <Select
                withLabel
                label='METRIC TYPE'
                containerClass=''
                selectedOption={METRIC_TYPE.find(option => option.value === item.metrics)}
                options={METRIC_TYPE}
                name='metrics'
                onChange={(e) => handleChangeMetrics(i, e)}
              />

              </div>
            </div>

            <div className=' flex flex-wrap justify-between gap-5'>
              <Input
                withLabel
                containerClass='w-full mdLg:w-[45%] '
                label={'DUE DATE'}
                value={item?.dueDate}
                name='dueDate'
                onChange={(e) => handleChangeFormData(i, e)}
                type='date'
              />
              <Input
                withLabel
                label={'WEIGHT'}
                containerClass='w-full mdLg:w-[45%] '
                value={item?.weight}
                name='weight'
                onChange={(e) => handleChangeFormData(i, e)}
              />
              <div className='mdLg:w-[70%] mt-3'>
                <Select
                  withLabel
                  label='CYCLE'
                  containerClass=''
                  selectedOption={CYCLE.find(option => option.value === item.cycle)}
                  options={CYCLE}
                  name='cycle'
                  onChange={(e) => handleChangeCycle(i, e)}
                />
              </div>
              {i > 0 && formData.length > 1 && (
                <RiDeleteBin6Line onClick={() => deleteObj(i)} className='cursor-pointer' />
              )}
            </div>
            {item.keyResults.map((result, ind) => (
              <div key={ind}>
                <div className='space-y-6 mdLg:space-y-0 mdLg:flex mdLg:flex-wrap items-start gap-3 gap-y-6 justify-between'>
                  <div className=' flex flex-col gap-2 w-xs'>
                   <Input
                     withLabel
                     label={'key Result'}
                     containerClass=''
                     value={result?.keyResult}
                     name='keyResult'
                     onChange={(e) => handleChangeKeyResults(i, ind, e)}
                    />
                  </div>
                  <div className=' flex flex-col gap-2 w-full'>
                    <h6 className=' text-xs'>LEAD DEPARTMENT</h6>
                    <Select
                      placeholder={'Select lead department...'}
                      selectedOption={result.leadDept}
                      options={departmentsOption}
                      isLoading={loading}
                      onChange={({ value }) =>
                        handleChangeKeyResults(i, ind, value, 'leadDept', true)
                      }
                      name='leadDept'
                    />
                  </div>
                  <div className=' flex flex-col gap-2 w-full'>
                    <h6 className=' text-xs'>CONTRIBUTOR DEPARTMENT</h6>
                    <Select
                      placeholder={'Select contributor department...'}
                      selectedOption={result.contributorDept}
                      options={departmentsOption}
                      isLoading={loading}
                      onChange={({ value }) =>
                        handleChangeKeyResults(i, ind, value, 'contributorDept', true)
                      }
                      name='contributorDept'
                    />
                  </div>

                  <div className=' flex flex-col gap-2 w-full'>
                    <h6 className=' text-xs'>CONTRIBUTOR DEPARTMENT1</h6>
                    <Select
                      placeholder={'Select contributor department1...'}
                      selectedOption={result.contributorDept1}
                      options={departmentsOption}
                      isLoading={loading}
                      onChange={({ value }) =>
                        handleChangeKeyResults(i, ind, value, 'contributorDept1', true)
                      }
                      name='contributorDept1'
                    />
                  </div>

                  {ind > 0 && (
                    <MdDelete
                      className={`ml-[4rem] self-center  cursor-pointer `}
                      onClick={() => deleteKeyResults(i, ind)}
                    />
                  )}
                </div>
                <h6
                  className={`underline  justify-end cursor-pointer ${
                    ind === item.keyResults.length - 1 ? 'flex' : 'hidden'
                  }`}
                  onClick={() => addKeyResults(i)}>
                  +ADD MORE KEY RESULTS
                </h6>
              </div>
            ))}
          </div>
        ))}
        <div className='mx-auto flex justify-center mdLg:justify-start'>
          <Button
            text={'+ADD MORE CORPORATE OBJECTIVES'}
            className='bg-transparent border-purple-100 border hover:border-none '
            onClick={addObj}
          />
        </div>

        <div className='mx-auto flex justify-center mdLg:justify-start'>
          <Button
            type='submit'
            text={'SAVE'}
            onClick={saveObjective}
            isPurple
            className={'ml-auto'}
            disabled={loading}
            loading={loading}
          />
        </div>
      </form>
    </div>
    // </BaseModal>
  );
};

export default SetObjectives;
