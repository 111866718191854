import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import { apiService } from '../../../../helpers/api';
import { format, isThisWeek, isThisMonth, parseISO } from 'date-fns';
// import { Link } from 'react-router-dom';
// import Button from '../../../../components/button';
import ApplicationCards from '../../../../components/cards/people_recruit/ApplicationCards';
import SearchWithFilter from '../../../../components/general/SearchWithFilter';
import { JOB_APPLICANTS, JOB_DETAILS } from '../../../../router/router';

const ShortlistLayout = () => {
  const [loading, setLoading] = useState(false);
  const [vacancyDetails, setVacancyDetails] = useState([]);
  const { id } = useParams();
  
  const fetchVacancyDetails = async () => {
    setLoading(true);
    try {
      const res = await apiService.get(
        `JobVacancy/FetchAllJobVacancyByCompanyId`,
        { vacancyId: id },
        true
      );
      const response = res?.data?.data?.data;
      setVacancyDetails(response || []); // Ensure response is an array or empty
    } catch (err) {
      console.log('Error in fetching vacancy details: ', err)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVacancyDetails();
  }, []);

  // Filter jobs for this week and this month based on `dateCreated`
  const thisWeekJobs = vacancyDetails.filter(job => isThisWeek(parseISO(job.dateCreated)));
  const thisMonthJobs = vacancyDetails.filter(job => isThisMonth(parseISO(job.dateCreated)) || isThisWeek(parseISO(job.dateCreated)));

  return (
    <div className='space-y-5'>
      {/*<Link to={LEADERBOARD} className="flex justify-end">
				<Button text={"Leaderboard"} isPink 	/>
	</Link>*/}
      <div className=' bg-pink-100 py-4 flex rounded-xl items-center justify-between px-4 text-white-100'>
        <p className='font-medium'>Job Categories</p>
        <SearchWithFilter
          className={'text-white-100'}
          height='h-10'
          placeholder={'Search created jobs'}
          isRecruit
        />
      </div>

      <div className=" bg-white-100 rounded-md p-4 sm:px-10 space-y-6">
        {/* Jobs Created This Week */}
        <div className="space-y-4">
          <h6 className="text-black-100">This Week</h6>
          <div className="flex justify-start gap-4 items-center flex-wrap">
            {thisWeekJobs.length > 0 ? (
              thisWeekJobs.map((job) => (
                <ApplicationCards
                  key={job.vacancyId}
                  role={job.jobTitle}
                  company="Revohub Technologies" // Assuming this is the company name or you can use job.companyId if available
                  location={'Lagos'} // You can add job location if provided in the data
                  status={job.jobType}
                  dept={job.department}
                  link={JOB_APPLICANTS}
                />
              ))
            ) : (
              <p>No jobs posted this week.</p>
            )}
          </div>
        </div>

        {/* Jobs Created This Month */}
        <div className="space-y-4">
          <h6 className="text-black-100">This Month</h6>
          <div className="flex justify-start gap-4 items-center flex-wrap">
            {thisMonthJobs.length > 0 ? (
              thisMonthJobs.map((job) => (
                <ApplicationCards
                  key={job.vacancyId}
                  role={job.jobTitle}
                  company="Revohub Technologies"
                  location={'Lagos'} // Assuming Lagos, but this can be dynamic based on your data
                  status={job.jobType}
                  dept={job.department}
                  link={JOB_DETAILS}
                />
              ))
            ) : (
              <p>No jobs posted this month.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortlistLayout;
