import React, { useState, useEffect } from 'react';
import { emptyKeyResults, emptyPerformanceForm } from '../../../../../../helpers/constant';
import { getObjectiveOption } from '../../../../../../helpers/functions';
import { API } from '../../../../../../utils/apis';
import GoalsLayout from '../../goals-layout';
import PerformanceOnly from '../performance-only';
import { useNavigate } from 'react-router';
import { STORAGE_KEYS } from '../../../../../../helpers/constant';

const PerformanceGoals = (/*{formData, setFormData}*/) => {
  const emptyForm = {
    performanceId: 0,
    employeeId: 0,
    departmentId: 0,
    employeeGroupId: 0,
    performanceTemplate: 0,
    approverId: 0,
    isDraft: true,
    goal: [
      {
        objectiveId: 0,
        description: '',
        goalType: 1,
        targets: [
          {
            target: '',
            metrics: 1,
            value: '',
            dueDate: '',
            weight: 0,
          },
        ],
        performanceGoalCategory: 1,
      },
    ],
    behavioural: [],
    technical: [],
    groupType: 1,
  };
  const staffId = localStorage.getItem(STORAGE_KEYS?.EMPLOYEE_ID);
  const [formData, setFormData] = useState(emptyForm);
  const [objectiveOption, setObjectiveOption] = useState([]);
  const [keyResultOption, setKeyResultOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loadObjectiveOptions = async () => {
    const res = await API.getObjective();
    setObjectiveOption(getObjectiveOption(res));
  };

  useEffect(() => {
    loadObjectiveOptions();
  }, []);

  const submitData = async (e) => {
    setLoading(true);
    console.log(formData);
    console.log('We are all together');
    await API.submitData(formData, navigate);
    setLoading(false);
    e.preventDefault();
  };

  return (
    <GoalsLayout title='Performance Goals' submitData={submitData}>
      <PerformanceOnly 
       formData={formData} 
       setFormData={setFormData}
       objectiveOption={objectiveOption}
       employeeId={formData.employeeId}
      />
    </GoalsLayout>
  );
};

export default PerformanceGoals;
